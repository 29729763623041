<template>
  <b-card-body
    ref="messages"
    class="mt-1 height-500 overflow-y-scroll messages-body"
  >
    <message-item
      v-for="(message,key) in dataItem.messages"
      :key="key"
      :item-data="message"
    />
  </b-card-body>
</template>

<script>
import {
  BCardBody,
} from 'bootstrap-vue'
import MessageItem from '@/views/Admin/Messages/View/MessageItem.vue'

export default {
  name: 'CardBody',
  components: {
    BCardBody,
    MessageItem,
  },
  computed: {
    dataItem() {
      return this.$store.getters['chatMessages/dataItem']
    },
  },
  mounted() {
    this.toBottom()
  },
  updated() {
    this.toBottom()
  },
  methods: {
    async toBottom() {
      this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight
    },
  },
}
</script>
