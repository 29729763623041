<template>
  <div>
    <div
      class="mb-1 d-flex"
      :class="itemData.id_users? 'justify-content-end' : 'justify-content-start'"
    >
      <div
        class="w-50 text-white pt-1 pl-1 pb-1 rounded-lg d-flex align-items-center justify-content-between"
        :class="itemData.id_users? 'bg-light-secondary' : 'bg-light-warning'"
      >
        <div>
          <template v-if="itemData.image">
            <b-button
              class="p-0 border-0"
              variant="warning"
              @click="openModal"
            >
              <div class="width-200 height-200 overflow-hidden rounded">
                <b-img
                  :src="baseURL + '/media/messages/' + itemData.thumb"
                  fluid
                />
              </div>
            </b-button>
            <b-modal
              v-model="imageModal"
              hide-footer
              centered
              size="lg"
            >
              <b-img
                :src="baseURL + '/media/messages/' + itemData.image"
                fluid
              />
            </b-modal>
          </template>
          <template v-else>
            <div>
              {{ itemData.message }}
            </div>
          </template>
          <div class="font-small-1 mt-1">
            {{ moment(itemData.created).format('llll') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BImg, BButton, BModal } from 'bootstrap-vue'

export default {
  name: 'MessageItem',
  components: {
    BImg,
    BButton,
    BModal,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      imageModal: false,
      baseURL: this.$store.state.app.baseURL,
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  methods: {
    openModal() {
      this.imageModal = true
    },
  },
}
</script>
