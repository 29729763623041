<template>
  <b-card-header class="border-bottom">
    <b-card-title class="d-block w-100">
      <div class="d-flex justify-content-between">
        <div class="d-flex">
          <div>
            <b-avatar
              :text="dataItem.avatar"
              variant="primary"
              class="mr-1"
            />
          </div>
          <div>
            <div>{{ dataItem.company }}</div>
            <div class="font-small-2 text-primary">
              {{ dataItem.customer_account }}
              <span
                v-if="eventMessage.message"
              >
                Yazıyor...
              </span>
            </div>
          </div>
        </div>
        <div v-if="userData.id === dataItem.id_users">
          <b-button
            variant="flat-warning"
            class="btn-icon"
            :disabled="dataItem.closed"
            @click="closeAction"
          >
            <FeatherIcon
              icon="PowerIcon"
            />
            Görüşmeyi Sonlandır
          </b-button>
        </div>
      </div>
    </b-card-title>
  </b-card-header>
</template>

<script>
import {
  BCardHeader,
  BCardTitle,
  BAvatar,
  BButton,
} from 'bootstrap-vue'

export default {
  name: 'CardHeader',
  components: {
    BCardHeader,
    BCardTitle,
    BAvatar,
    BButton,
  },
  props: {
    eventMessage: {
      type: Object,
      required: true,
    },
    closeAction: {
      type: Function,
      required: true,
    },
  },
  computed: {
    userData() {
      return JSON.parse(localStorage.getItem('userData'))
    },
    dataItem() {
      return this.$store.getters['chatMessages/dataItem']
    },
  },
}
</script>
