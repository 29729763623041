<template>
  <b-card no-body>
    <card-header
      :event-message="eventMessage"
      :close-action="closedChat"
    />
    <card-body />
    <card-footer
      :send-message="sendMessage"
    />
  </b-card>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'
import CardHeader from '@/views/Admin/Messages/View/CardHeader.vue'
import CardBody from '@/views/Admin/Messages/View/CardBody.vue'
import CardFooter from '@/views/Admin/Messages/View/CardFooter.vue'
// eslint-disable-next-line no-undef
const socket = io('https://ws.code64.net.tr/sharell')
export default {
  name: 'PageView',
  components: {
    BCard,
    CardHeader,
    CardBody,
    CardFooter,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      eventMessage: {
        name: null,
        room: null,
        message: null,
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['chatMessages/dataItem']
    },
    messageItem() {
      return this.$store.getters['chatMessages/messageItem']
    },
  },
  watch: {
    messageItem: {
      deep: true,
      handler() {
        this.eventMessages()
      },
    },
  },
  created() {
    this.getData()
    socket.on('newMessage', data => {
      this.dataItem.messages.push(data.message)
    })
    socket.on('eventMessages', data => {
      this.eventMessage = data
    })
    socket.on('closedChat', data => {
      if (data.message) {
        this.getData()
      }
    })
  },
  methods: {
    sendMessage() {
      this.messageItem.room_code = this.dataItem.room_code
      if (this.messageItem.message) {
        this.$store.dispatch('chatMessages/sendMessage', this.messageItem)
          .then(res => {
            if (res.id) {
              socket.emit('sendMessage', {
                message: res.message,
                room: this.messageItem.room_code,
              })
              this.messageItem.message = null
            }
          })
      }
    },
    joinRoom(roomCode) {
      socket.emit('joinRoom', {
        name: this.userData.username,
        room: roomCode,
      })
    },
    eventMessages() {
      let message = {
        name: null,
        room: this.$route.params.room_code,
        message: null,
      }
      if (this.messageItem.message) {
        message = {
          name: this.userData.username,
          room: this.$route.params.room_code,
          message: 'Yazıyor...',
        }
      }
      socket.emit('eventMessages', message)
    },
    getData() {
      this.$store.dispatch('chatMessages/getDataItem', this.$route.params.room_code)
        .then(response => {
          if (response.room_code) {
            this.joinRoom(response.room_code)
          }
        })
    },
    closedChat() {
      this.$swal({
        title: 'Uyarı!',
        text: 'Görüşme kalıcı olarak kapatılacaktır! Devam etmek istiyor musunuz?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Devam Et',
        cancelButtonText: 'Vazgeç',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('chatMessages/closedChat', {
            id: this.dataItem.id,
          })
            .then(response => {
              if (response.status) {
                this.$store.dispatch('chatMessages/sendMessage', {
                  message: 'Görüşme satış temsilcisi tarafından sonlandırıldı.',
                  room_code: this.$route.params.room_code,
                })
                  .then(res => {
                    if (res.id) {
                      socket.emit('sendMessage', {
                        message: res.message,
                        room: this.$route.params.room_code,
                      })
                      socket.emit('closedChat', {
                        room: this.$route.params.room_code,
                        message: 'Görüşme satış temsilcisi tarafından sonlandırıldı.',
                        username: this.userData.username,
                      })
                    }
                  })
                this.getData()
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı!',
                  text: 'Görüşme kalıcı olarak kapatılmıştır.',
                  confirmButtonText: 'Kapat',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
